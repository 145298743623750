<template>
  <div class="rounds-list" v-if="loaded">
    <template v-for="(round, roundIndex) in roundInfo.rounds">
      <div
        class="round-card"
        v-for="(pool, poolIndex) in round.pools"
        :key="pool.id"
      >
        <div class="header">
          <h4>
            Round {{ roundIndex + 1 }}
            <span v-if="round.pools.length > 1">
              - Pool {{ $store.state.poolConversion[poolIndex] }}</span
            >
          </h4>
          <div class="status-label strom" v-if="pool.status == 'COMPLETED'">
            Finished
          </div>
          <div class="status-label grass" v-else-if="pool.status == 'OPEN'">
            Live
          </div>
          <div class="status-label sunset" v-else>Upcoming</div>
        </div>
        <p class="date">{{ poolDate(pool) }}</p>
        <div class="course">
          <p class="course-name">
            <strong>{{ pool.layoutVersion.layout.course.name }}</strong>
          </p>
          <div class="layout-wrapper">
            <p class="layout-name">
              {{ pool.layoutVersion.layout.name }}
              <Verified
                v-if="pool.layoutVersion.layout.course.type == 'PUBLIC'"
              />
            </p>
            <p class="layout-toggle" @click="toggleShownLayout(pool)">
              <template v-if="shownLayouts.includes(pool.id)"
                >Hide layout</template
              >
              <template v-else>Show layout</template>
            </p>
            <slide-up-down
              tag="div"
              class="layout"
              :active="shownLayouts.includes(pool.id)"
              :duration="300"
            >
              <ul
                class="holes"
                :class="{ wrap: wrapHoles(pool.layoutVersion.holes) }"
              >
                <li
                  class="hole"
                  v-for="hole in pool.layoutVersion.holes"
                  :key="hole.id"
                  :class="{}"
                >
                  <div class="hole-name">{{ displayHole(hole) }}</div>
                  <div class="hole-par" :class="parEval(hole)">
                    {{ hole.par }}
                  </div>
                </li>
              </ul>
            </slide-up-down>
          </div>
        </div>
        <router-link
          :to="{
            name: 'public-event-pool-results',
            params: { eventId: $route.params.eventId, poolId: pool.id },
          }"
        >
          <button class="view-round">View round</button>
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import SlideUpDown from "vue-slide-up-down";
import Verified from "@/assets/icons/Verified";
export default {
  name: "PublicEventRounds",
  components: {
    SlideUpDown,
    Verified,
  },
  data() {
    return {
      shownLayouts: [],
      roundInfo: null,
      loaded: false,
    };
  },
  computed: {},
  methods: {
    toggleShownLayout(pool) {
      if (this.shownLayouts.includes(pool.id)) {
        this.shownLayouts = this.shownLayouts.filter(
          (poolId) => poolId != pool.id
        );
      } else {
        this.shownLayouts.push(pool.id);
      }
    },
    poolDate(pool) {
      return moment(pool.date).format("ddd D MMM [at] HH:mm");
    },
    displayHole(hole) {
      return hole.name == null ? hole.number : hole.name;
    },
    parEval(hole) {
      if (hole.par <= 3) {
        return "low";
      } else if (hole.par == 4) {
        return "mid";
      } else return "high";
    },
    wrapHoles(holes) {
      let wrap = false;

      if (holes.length > 27) {
        wrap = true;
      } else if (holes.length > 18) {
        holes.forEach((hole) => {
          if (hole.name != null) {
            wrap = true;
          }
        });
      }

      return wrap;
    },
    async loadData() {
      let roundInfo = await this.$axios({
        data: {
          query: `
          {
            event(eventId: "${this.$router.currentRoute.params.eventId}") {
              id
              name
              rounds{
                id
                pools{
                  id
                  status
                  startMethod
                  date
                  layoutVersion{
                    holes{
                      id
                      number
                      par
                      name
                    }
                    layout{
                      id
                      name
                      type
                      course{
                        name
                        type
                      }
                    }
                  }
                }
              }
              tour {
                id
                name
                events {
                  id
                }
              }
            }
          }
          `,
        },
      });

      this.roundInfo = roundInfo.data.data.event;
      this.loaded = true;
    },
  },
  beforeMount() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.rounds-list {
  padding: 0 12px;
}
.round-card {
  width: 100%;
  border: 1px solid $sleet;
  border-radius: 10px;
  padding: 18px 20px 22px 20px;
  margin-bottom: 24px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      margin-bottom: 0;
    }
    .label {
      line-height: normal;
      padding: 4px 8px;
      color: white;
      text-transform: uppercase;
      border-radius: 11px;
      font-size: 10px;
      letter-spacing: 1px;
      @include Gilroy-Bold;

      &.finished {
        background: $midnight;
      }
      &.live {
        background: $grass;
      }
      &.upcoming {
        background: $sunset;
      }
    }
  }
  .date {
    margin-top: 8px;
    font-size: 14px;
    margin-bottom: 0;
  }

  .course {
    margin-top: 18px;
    .course-name {
      margin: 0;
      strong {
        @include Gilroy-Bold;
      }
    }

    .layout-wrapper {
      margin-top: 4px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .layout-name {
        font-size: 14px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        line-height: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: calc(100% - 90px);

        svg {
          margin-left: 6px;
        }
      }
      .layout-toggle {
        margin: 0;
        color: $ocean;
        font-size: 14px;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .holes {
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        margin-top: 16px;

        .hole {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p {
            margin: 0;
          }

          .par {
            background: $par;
            width: 100%;
            text-align: center;
            font-size: 12px;
            padding: 4px 0;
          }
          .number {
            margin-bottom: 8px;
            width: 100%;
            text-align: center;
            font-size: 12px;
          }
        }
      }

      .layout {
        width: 100%;
        .holes {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          width: 100%;

          &.wrap {
            flex-wrap: wrap;
            justify-content: flex-start;
            padding-bottom: 24px;
            margin: 0;

            li {
              width: calc(5.5555555556%);
              flex: unset;
              box-sizing: border-box;
            }
          }

          li {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-right: 1px solid white;
            margin-top: 15px;
            max-width: 40px;

            &:last-child {
              border-right: 0px solid white;
            }
            .hole-name {
              font-size: 10px;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 5px 0;
            }
            .hole-par {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              font-size: 10px;
              @include Gilroy-Bold;

              padding: 5px 0;

              &.low {
                background-color: $mist;
              }
              &.mid {
                background-color: $sleet;
              }
              &.high {
                background-color: $blizzard;
              }
            }
          }
        }
      }
    }
  }

  .view-round {
    margin-top: 24px;
    width: 100%;
    background: $cloud;
    transition: all ease 0.3s;
    border: none;
    font-size: 16px;
    border-radius: 6px;
    &:hover {
      background: $fog;
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .rounds-list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .round-card {
    width: 48%;
    padding: 20px 25px 26px 25px;
    margin-bottom: 48px;

    .header {
      h4 {
        font-size: 24px;
      }
    }
    .date {
      font-size: 16px;
    }
    .course {
      .layout-wrapper {
        margin-top: 2px;
        .layout-name {
          font-size: 16px;
        }
      }
    }

    .view-round {
      height: 48px;
      @include Gilroy-Bold;
    }
  }
}

@media (min-width: 992px) {
  .rounds-list {
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .round-card {
    width: 31.3%;
    margin-right: 3%;
    padding: 20px 25px 26px 25px;
    margin-bottom: 48px;

    &:nth-child(3n) {
      margin-right: 0;
    }
    .header {
      h4 {
        font-size: 24px;
      }
    }
    .date {
      font-size: 16px;
    }
    .course {
      .layout-wrapper {
        margin-top: 2px;
        .layout-name {
          font-size: 16px;
          svg {
            margin-left: 7px;
          }
        }
      }
    }

    .view-round {
      height: 48px;
      @include Gilroy-Bold;
    }
  }
}
</style>

